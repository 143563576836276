import React, { useEffect, useState } from 'react';
import List from './List';
import { axiosInstance } from 'App';

const WIPWrapper = () => {
    const [salesRepList, setSalesRepList] = useState();
    const [jobStatusList, setJobStatusList] = useState();

    useEffect(() => {
        const fetchSalesList= async () => {
            const result = await axiosInstance({ url: '/users?role=Sales' });
    
            const options = result.data.map(({ userID, givenName, familyName }) => ({
                label: givenName + ' ' + familyName,
                id: userID
            }));

            setSalesRepList(options);
        };

        fetchSalesList();

        const fetchJobStatusList= async () => {
            const result = await axiosInstance({ url: '/refdata/JobStatus' });

            const options = result.data
            .filter(({displayOrder}) => displayOrder > 0)
            .map(({ id, data }) => ({
                label: data,
                id: id
            }));

            setJobStatusList(options);
        };

        fetchJobStatusList();
    }, []);

    const isLoading = !(salesRepList && jobStatusList);

    return <List isLoading={isLoading} salesRepList={salesRepList} jobStatusList={jobStatusList} />;
};

export default WIPWrapper;
