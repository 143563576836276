import React, { createContext, useState, useEffect, useMemo, useContext } from 'react';
import { Auth } from '@aws-amplify/auth';
import { axiosInstance } from 'App';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [userLoading, setUserLoading] = useState(true);
    const [user, setUser] = useState(null);
    const [userAttr, setUserAttr] = useState({
        enabled: null,
        userID: null,
        roles: [],
    });

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then((user) => {
                console.log(user);
                axiosInstance.defaults.headers.common.Authorization = `Bearer ${user.signInUserSession.accessToken.jwtToken}`;
                setUser(user);
                getUserAttr();
            })
            .catch(() => setUser(null));

        createAuthRefreshInterceptor(axiosInstance, (failedRequest) =>
            Auth.currentSession()
                .then((session) => {
                    axiosInstance.defaults.headers.common.Authorization = `Bearer ${session.accessToken.jwtToken}`;
                    failedRequest.response.config.headers['Authorization'] = `Bearer ${session.accessToken.jwtToken}`;

                    return Promise.resolve();
                })
                .catch((err) => console.log(err)),
        );
    }, []);

    const getUserAttr = async () => {
        const result = await axiosInstance({ url: '/users/self' });
        const { enabled, userID, roles } = result.data;

        setUserAttr({ enabled: enabled, userID: userID, roles: roles });
        setUserLoading(false);
    };

    const login = (usernameOrEmail, password) =>
        Auth.signIn(usernameOrEmail, password)
            .then((user) => {
                if (user?.challengeName) {
                    return { user };
                }

                axiosInstance.defaults.headers.common.Authorization = `Bearer ${user.signInUserSession.accessToken.jwtToken}`;

                setUser(user);
                getUserAttr();

                return { message: 'You were successfully logged in', severity: 'success' };
            })
            .catch((err) => {
                return { message: err.message, severity: 'error' };
            });

    // this doesn't throw an exception ??

    const currentSession = () => Auth.currentSession()
    const credentials = () =>
        Auth.currentUserCredentials().then((c) => {

            if ( 'authenticated' in c) return c
            throw new Error(c)
        });

    const logout = () => {
        setUserLoading(true);
        return Auth.signOut().then((data) => {
            setUser(null);
            setUserAttr();

            return data;
        });
    };

    const passwordReminder = (username) =>
        Auth.forgotPassword(username)
            .then(() => {
                return { message: 'A verification code has been emailed to you', severity: 'info' };
            })
            .catch((err) => {
                return { message: err.message, severity: 'error' };
            });

    const resetPassword = async (username, code, new_password) =>
        Auth.forgotPasswordSubmit(username, code, new_password)
            .then(() => {
                return { message: 'Your password has been reset', severity: 'success' };
            })
            .catch((err) => {
                return { message: err.message, severity: 'success' };
            });

    const completeNewPassword = async (user, newPassword) =>
        Auth.completeNewPassword(user, newPassword)
            .then(() => {
                getUserAttr();
                return { message: 'Your password has been reset', severity: 'success' };
            })
            .catch((err) => {
                return { message: err.message, severity: 'error' };
            });

    const values = useMemo(
        () => ({ user, userAttr, userLoading, login, credentials, currentSession, logout, passwordReminder, resetPassword, completeNewPassword }),
        [user, userAttr, userLoading],
    );

    return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    const context = useContext(AuthContext);

    if (context === undefined) {
        throw new Error('`useAuth` hook must be used within a `AuthProvider` component');
    }
    return context;
};
