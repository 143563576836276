import { Document, Page, Text, View, StyleSheet, Font } from '@react-pdf/renderer';
import { formatter } from '../../Quotes/Edit/PDFStyles';
import addWorkHours from 'components/AddWorkHours';
import { salesRepInitials } from '../../Quotes/components/QuoteFields';

export const initials = name => name && name.split(/\s/).reduce((response,word) => response+=word.slice(0,1),'');

Font.register({
    family: 'Lato',
    src: `https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjx4wWw.ttf`,
  });
  
Font.register({
    family: 'Lato Bold',
    src: `https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwiPHA.ttf`,
});

export const styles = StyleSheet.create({
    page: {
        position: 'relative',
        width: '100%',
        marginTop: 15,
        paddingBottom: 50
    },
    w5: { width: '5%' },
    w10: { width: '10%' },
    w15: { width: '15%' },
    w20: { width: '20%' },
    w25: { width: '25%' },
    w30: { width: '30%' },
    w45: { width: '45%' },
    w50: { width: '50%' },
    w55: { width: '55%' },
    w65: { width: '65%' },
    w70: { width: '70%' },
    w80: { width: '80%' },
    w85: { width: '85%' },
    w100: { width: '100%' },
    mR: {
        marginRight: 1
    },
    mL: {
        marginLeft: 1
    },
    flex: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
    },
    table: { 
        marginLeft: '1%',
        marginRight: '1%',
        width: '98%',
    },
    boldText: {
        fontSize: 10,
        fontFamily: 'Lato Bold',
    },
    smallText: {
        fontSize: 8,
        marginTop: 2,
        fontFamily: 'Lato',
    },
    tC: {
        textAlign: 'left',
        marginBottom: 1,
        marginLeft: 4
    },
    bR: {
        borderRight: '1px solid grey',
    },
    cellSmall: {
        width: 25,
        borderRight: '1px solid grey'
    },
    cellDesc: {
        width: 207,
        borderRight: '1px solid grey',
        margin: '0 1'
    },
    cellDraw: {
        width: 90,
        borderRight: '1px solid grey'
    },
    cellStock: {
        width: 38,
        borderRight: '1px solid grey'
    },
    cellSup: {
        width: 55,
        borderRight: '1px solid grey'
    },
    cellMid: {
        width: 50,
        borderRight: '1px solid grey'
    },
    cellRec: {
        width: 40,
    },
    odd: {

    },
    even: {
        backgroundColor: '#d6d6d6'
    },
    footer: {
        fontSize: 8,
        position: 'absolute',
        bottom: 0,
        right: '2%',
        color: 'grey',
        height: 40
    },
});

const PDF = ({ res }) => {
    let priceCounter = 0;
    return (
        <Document>
            <Page wrap size="A3" orientation="landscape" style={styles.page}>
                <View fixed style={[styles.flex, styles.table, {backgroundColor: '#333148', color: '#d6d6d6'}]}>
                    <View style={[{width: 100}]}>
                        <Text style={[styles.boldText, styles.tC]}>Job Status</Text>
                    </View>
                    <View style={[{width: 100}]}>
                        <Text style={[styles.boldText, styles.tC]}>Latest Status Note</Text>
                    </View>
                    <View style={[{width: 50}]}>
                        <Text style={[styles.boldText, styles.tC]}>Job No</Text>
                    </View>
                    <View style={[{width: 120}]}>
                        <Text style={[styles.boldText, styles.tC]}>Company Name</Text>
                    </View>
                    <View style={[{width: 30}]}>
                        <Text style={[styles.boldText, styles.tC]}>PS</Text>
                    </View>
                    <View style={[{width: 70}]}>
                        <Text style={[styles.boldText, styles.tC]}>Quote No</Text>
                    </View>
                    <View style={[{width: 140}]}>
                        <Text style={[styles.boldText, styles.tC]}>Comments</Text>
                    </View>
                    <View style={[{width: 130}]}>
                        <Text style={[styles.boldText, styles.tC]}>Production Notes</Text>
                    </View>                    
                    <View style={[{width: 30}]}>
                        <Text style={[styles.boldText, styles.tC]}>Qty</Text>
                    </View>
                    <View style={[{width: 60}]}>
                        <Text style={[styles.boldText, styles.tC]}>Design</Text>
                    </View>
                    <View style={[{width: 60}]}>
                        <Text style={[styles.boldText, styles.tC]}>Size</Text>
                    </View>
                    <View style={[{width: 30}]}>
                        <Text style={[styles.boldText, styles.tC]}>Arr</Text>
                    </View>
                    <View style={[{width: 50}]}>
                        <Text style={[styles.boldText, styles.tC]}>Order Date</Text>
                    </View>
                    <View style={[{width: 50}]}>
                        <Text style={[styles.boldText, styles.tC]}>Eng Start Date</Text>
                    </View>
                    <View style={[{width: 50}]}>
                        <Text style={[styles.boldText, styles.tC]}>Eng Due Date</Text>
                    </View>
                    <View style={[{width: 50}]}>
                        <Text style={[styles.boldText, styles.tC]}>Fab Start Date</Text>
                    </View>
                    <View style={[{width: 50}]}>
                        <Text style={[styles.boldText, styles.tC]}>Client Due Date</Text>
                    </View>
                    <View style={[{width: 50}]}>
                        <Text style={[styles.boldText, styles.tC]}>Client Rev Due Date</Text>
                    </View>
                    <View style={[{width: 50}]}>
                        <Text style={[styles.boldText, styles.tC]}>Order Number</Text>
                    </View>
                    <View style={[{width: 70}]}>
                        <Text style={[styles.boldText, styles.tC, {textAlign: 'right', marginRight: 2}]}>Price</Text>
                    </View>
                </View>


                {res.map(({ quoteID, revision, itemID, clientName, jobID, jobStatusName, jobStatusNotice, combinedPrice, salesRepName, ...rest }, index) => {
                        const rowStyle = index % 2 ? styles.odd : styles.even;

                        const end = res.length === index+1;

                        const diffAsNext = end || jobStatusName !== res[index+1]?.jobStatusName;

                        const diffAsPrev = jobStatusName !== res[index-1]?.jobStatusName;

                        if (diffAsPrev) {
                            priceCounter = 0
                        }

                        if (!end) {
                            priceCounter = priceCounter + (res[index].combinedPrice || 0);
                        } 
                        
                        return (
                            <>
                                <View style={[styles.flex, styles.table, rowStyle ]}>
                                    <View style={[{width: 100}]}>
                                        <Text style={[styles.smallText, styles.tC]}>{jobStatusName}</Text>
                                    </View>
                                    <View style={[{width: 100}]}>
                                        <Text style={[styles.smallText, styles.tC]}>{jobStatusNotice}</Text>
                                    </View>
                                    <View style={[{width: 50}]}>
                                        <Text style={[styles.smallText, styles.tC]}>{jobID}</Text>
                                    </View>
                                    <View style={[{width: 120}]}>
                                        <Text style={[styles.smallText, styles.tC]}>{clientName}</Text>
                                    </View>
                                    <View style={[{width: 30}]}>
                                        <Text style={[styles.smallText, styles.tC]}>{initials(rest.engineeringDraftsPerson)}</Text>
                                    </View>
                                    <View style={[{width: 70}]}>
                                        <Text style={[styles.smallText, styles.tC]}>{quoteID}{itemID}{revision}{salesRepName != null ? salesRepInitials(salesRepName) : ''}</Text>
                                    </View>
                                    <View style={[{width: 140}]}>
                                        <Text style={[styles.smallText, styles.tC]}>{rest.commercialsExtra}</Text>
                                    </View>
                                    <View style={[{width: 130}]}>
                                        <Text style={[styles.smallText, styles.tC]}>{rest.productionNotes}</Text>
                                    </View>
                                    <View style={[{width: 30}]}>
                                        <Text style={[styles.smallText, styles.tC]}>{rest.fanQty}</Text>
                                    </View>
                                    <View style={[{width: 60}]}>
                                        <Text style={[styles.smallText, styles.tC]}>{rest.fanDesign}</Text>
                                    </View>
                                    <View style={[{width: 60}]}>
                                        <Text style={[styles.smallText, styles.tC]}>{rest.fanSize}</Text>
                                    </View>
                                    <View style={[{width: 30}]}>
                                        <Text style={[styles.smallText, styles.tC]}>{rest.fanArrangement}</Text>
                                    </View>
                                    <View style={[{width: 50}]}>
                                        <Text style={[styles.smallText, styles.tC]}>{rest.commercialsOrderDate && new Date(rest.commercialsOrderDate).toLocaleDateString("en-AU", {day: '2-digit', month: '2-digit', year: '2-digit'})}</Text>
                                    </View>
                                    <View style={[{width: 50}]}>
                                        <Text style={[styles.smallText, styles.tC]}>{rest.engineeringExpectedStart && new Date(rest.engineeringExpectedStart).toLocaleDateString("en-AU", {day: '2-digit', month: '2-digit', year: '2-digit'})}</Text>
                                    </View>
                                    <View style={[{width: 50}]}>
                                        <Text style={[styles.smallText, styles.tC]}>{rest.engineeringExpectedStart && rest.commercialsEngAllowed && String(addWorkHours(rest.engineeringExpectedStart, rest.commercialsEngAllowed).toLocaleDateString('en-AU', {year: "2-digit", month:"2-digit", day:"2-digit"}))}</Text>
                                    </View>
                                    <View style={[{width: 50}]}>
                                        <Text style={[styles.smallText, styles.tC]}>{rest.productionFabExpectedStart && new Date(rest.productionFabExpectedStart).toLocaleDateString("en-AU", {day: '2-digit', month: '2-digit', year: '2-digit'})}</Text>
                                    </View>
                                    <View style={[{width: 50}]}>
                                        <Text style={[styles.smallText, styles.tC]}>{rest.commercialsDueDate && new Date(rest.commercialsDueDate).toLocaleDateString("en-AU", {day: '2-digit', month: '2-digit', year: '2-digit'})}</Text>
                                    </View>
                                    <View style={[{width: 50}]}>
                                        <Text style={[styles.smallText, styles.tC]}>{rest.commercialsRevisedDueDate && new Date(rest.commercialsRevisedDueDate).toLocaleDateString("en-AU", {day: '2-digit', month: '2-digit', year: '2-digit'})}</Text>
                                    </View>
                                    <View style={[{width: 50}]}>
                                        <Text style={[styles.smallText, styles.tC]}>{rest.commercialsOrderNumber}</Text>
                                    </View>
                                    <View style={[{width: 70}]}>
                                        <Text style={[styles.smallText, styles.tC, {textAlign: 'right', marginRight: 4}]}>{formatter.format(combinedPrice || 0)}</Text>
                                    </View>
                                </View>

                                {diffAsNext && 
                                    <View style={[styles.flex, styles.table, {borderTop: '2 #333148 solid', borderBottom: '2 #333148 solid', marginBottom: 0}]}>
                                        {/* <View style={[{width: 1120}]}></View> */}
                                        <View style={[{width: '100%'}]}>
                                            <Text style={[styles.smallText, styles.tC, {textAlign: 'right', marginRight: 4}]}>{formatter.format(priceCounter)}</Text>
                                        </View>
                                    </View>
                                }
                            </>
                        );
                })} 
                <View fixed style={[styles.flex, styles.footer]}>         
                    <Text render={({ pageNumber, totalPages }) => (
                        `${pageNumber} / ${totalPages} - ${new Date().toLocaleDateString("en-AU", {day: '2-digit', month: '2-digit', year: '2-digit'})}` 
                    )} fixed />
                </View>
            </Page>
        </Document>
    );
};

export default PDF;
