import { useState, useEffect } from 'react';
import Form from './Form';
import { axiosInstance } from 'App';
import { useParams } from 'react-router-dom';

const categories = [
    'motor',
    'impeller',
    'liner',
    'hub',
    'hubBush',
    'fanPulley',
    'driveMotorPulley',
    'driveBelt',
    'driveBeltGuard',
    'driveSlideRails',
    'balance',
    'shaft',
    'shaftGuard',
    'bearingDE',
    'bearingNDE',
    'shaftSeal',
    'coupling',
    'couplingGuard',
    'case',
    'inletCone',
    'pedestal',
    'base',
    'baseMount',
    'inletDamper',
    'inletBox',
    'inletSilencer',
    'inletFlexible',
    'inletVanes',
    'evase',
    'outletDamper',
    'outletSilencer',
    'outletFlexible',
    'internalCoating',
    'externalCoating',
    'lagClad',
];

const formatData = data => ({
    attributes: data.attributes ? {
        ...data.attributes,
        ...categories.reduce((acc, field)=>{
            const orderDate = `${field}OrderDate`;
            const orderDue = `${field}OrderDue`;
            return {
                ...acc, 
                [orderDate]: data.attributes[orderDate] && data.attributes[orderDate].slice(0, 10),
                [orderDue]: data.attributes[orderDue] && data.attributes[orderDue].slice(0, 10)
            }
        },{}),
        commercialsOrderDate: data.attributes.commercialsOrderDate  && data.attributes.commercialsOrderDate.slice(0, 10),
        commercialsDueDate: data.attributes.commercialsDueDate  && data.attributes.commercialsDueDate.slice(0, 10),
        commercialsRevisedDueDate: data.attributes.commercialsRevisedDueDate  && data.attributes.commercialsRevisedDueDate.slice(0, 10),
        commercialsWarrantyFrom: data.attributes.commercialsWarrantyFrom  && data.attributes.commercialsWarrantyFrom.slice(0, 10),
        engineeringExpectedStart: data.attributes.engineeringExpectedStart  && data.attributes.engineeringExpectedStart.slice(0, 10),
        productionFabExpectedStart: data.attributes.productionFabExpectedStart  && data.attributes.productionFabExpectedStart.slice(0, 10),
        productionFitExpectedStart: data.attributes.productionFitExpectedStart  && data.attributes.productionFitExpectedStart.slice(0, 10),
        qaCompletionDate: data.attributes.qaCompletionDate  && data.attributes.qaCompletionDate.slice(0, 10),
        qaSentDate: data.attributes.qaSentDate  && data.attributes.qaSentDate.slice(0, 10),
        qaApprovedDate: data.attributes.qaApprovedDate  && data.attributes.qaApprovedDate.slice(0, 10),
        deliveryDateBooked: data.attributes.deliveryDateBooked  && data.attributes.deliveryDateBooked.slice(0, 10),
        deliveryDateSent: data.attributes.deliveryDateSent  && data.attributes.deliveryDateSent.slice(0, 10),
    } : {},
    jobStatusID: data.jobStatusID,
    jobStatusNotice: data.jobStatusNotice,
    jobStateID: data.jobStateID,
    lineItems: data.lineItems?.length ? data.lineItems.map((field, i)=>{
        return {
            ...field, 
            orderDate: field.orderDate && field.orderDate.slice(0, 10),
            orderDue: field.orderDue && field.orderDue.slice(0, 10)
        }
    }) : [],
    combinedCost: data.combinedCost,
    combinedPrice: data.combinedPrice
})

const Jobs = () => {
    const { jobID } = useParams();
    const [jobData, setJobData] = useState(null);
    const [quoteData, setQuoteData] = useState(null);
    const [clientData, setClientData] = useState(null);
    const [changesData, setChangesData] = useState(null);
    const [jobStatusList, setJobStatusList] = useState(null);
    const [jobStateList, setJobStateList] = useState(null);
    const [projectDraftspersonList, setProjectDraftsperson] = useState(null);

    useEffect(() => {
        const fetchQuoteData = async (quoteID, itemID, revision) => {
            const result = await axiosInstance({ url: `/quotes/${quoteID}/${itemID}/${revision}` });
            setQuoteData(result.data);
            fetchClient(result.data.clientID);
        };

        const fetchClient = async (clientID) => {
            const result = await axiosInstance({ url: `/clients/${clientID}` });

            setClientData(result.data);
        }

        const fetchChangesData = async () => {
            const result = await axiosInstance({ url: `/jobs/${jobID}/changes` });

            setChangesData(result.data);
        };
        
        const fetchJobData = async () => {
            const result = await axiosInstance({ url: `/jobs/${jobID}/customfans` });
            const jD = result.data;
            setJobData({
                ...jD,
                ...formatData(jD),
                updatedAt: jD.updatedAt
            });
            fetchQuoteData(jD.quoteID, jD.itemID, jD.revision);
        };

        const fetchJobStatusList = async () => {
            const result = await axiosInstance({ url: '/refdata/JobStatus' });

            const options = result.data
            .filter(({displayOrder}) => displayOrder > 0)
            .map(({ id, data }) => ({
                label: data,
                id: id
            }));

            setJobStatusList(options);
        };

        const fetchJobStateList = async () => {
            const result = await axiosInstance({ url: '/refdata/JobState' });

            const options = result.data
            .filter(({displayOrder}) => displayOrder > 0)
            .map(({ id, data }) => ({
                label: data,
                id: id
            }));

            setJobStateList(options);
        };

        const fetchProjectDraftspersonList = async () => {
            const result = await axiosInstance({ url: '/users' });
    
            const options = result.data.map(({ userID, givenName, familyName }) => ({
                label: givenName + ' ' + familyName,
                id: givenName + ' ' + familyName
            }));

            setProjectDraftsperson(options);
        };

        fetchJobData();
        fetchChangesData();
        fetchJobStatusList();
        fetchJobStateList();
        fetchProjectDraftspersonList();
    }, []);

    const loaded = jobData && quoteData && clientData && changesData && jobStatusList && projectDraftspersonList;

    return loaded ? <Form 
        jobData={jobData}
        quoteData={quoteData}
        clientData={clientData}
        changesData={changesData}
        jobStatusList={jobStatusList}
        jobStateList={jobStateList}
        projectDraftspersonList={projectDraftspersonList}
        categories={categories} 
        formatData={formatData} /> : null;
};

export default Jobs;
