import useStyles from '../screens/Quotes/styles';
import { FormControl } from '@material-ui/core';
import { Controller } from "react-hook-form";
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

const filter = createFilterOptions();

const SelectWithDataReactHook = ({ name, control, label, options, style, required=false, creatable=false }) => {
    const classes = useStyles();

    const getOpObj = id => {
        const tryFind = options?.find(obj => String(obj.id) === id);

        return tryFind || { label: id, id: id }
    }
        
    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState }) => {
                return (
                    <FormControl variant='outlined' className={classes.input} style={style}>
                        <Autocomplete
                        options={options}
                        freeSolo={creatable}
                        selectOnFocus={creatable}
                        handleHomeEndKeys={creatable}
                        onChange={(event, newValue) => {
                            if (typeof newValue === 'string') {
                                field.onChange(newValue)
                            } {
                                field.onChange(newValue?.id ? newValue.id : '')
                            }
                        }}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                    
                            // Suggest the creation of a new value
                            if (params.inputValue !== '') {
                                filtered.push({
                                inputValue: params.inputValue,
                                id: params.inputValue,
                                label: `Add "${params.inputValue}"`,
                                });
                            }
                    
                            return filtered;
                        }}
                        groupBy={(option) => option.group }
                        getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            if (typeof option === 'string') {
                              return option;
                            }
                            // Add "xxx" option created dynamically
                            if (option.inputValue) {
                              return option.inputValue;
                            }
                            // Regular option
                            return option.label;
                          }}
                        renderOption={(option) => option.label}
                        {...!!field.value ? { defaultValue: getOpObj(field.value) } : {}}
                        renderInput={params => (
                            <TextField
                                {...params}
                                required={required}
                                label={label}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                margin="dense"
                                style={{margin: '0'}} 
                                error={Boolean(fieldState.error)}
                                helperText={fieldState. error && fieldState.error.message}
                            />
                        )}

                        renderGroup={(params) => (
                            <li key={params.key}>
                                <div className={classes.groupLabel}>{params.group}</div>
                                <ul className={classes.groupList}>{params.children}</ul>
                            </li>
                        )}
                        />
                    </FormControl>
                )
            }}
        />
    );
};

export default SelectWithDataReactHook;


