import React, { useContext, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import ContentHeader from 'components/ContentHeader';
import ContentWrapper from 'components/ContentWrapper';
import BrowseData from 'components/BrowseData';
import BrowseProvider from 'providers/browse';
import { FilterValues } from 'types/browse';
import useStyles from '../styles';
import { Formik } from 'formik';
import SelectWithData from './SelectWithData';
import Input from './Input';
import BrowseContext from 'contexts/browseContext';
import NumberFormat from 'react-number-format';
import { useDispatch } from 'store';
import { addFeedback } from 'actions';
import { axiosInstance } from 'App';
import { saveAs } from 'file-saver';

import { Backdrop, CircularProgress } from '@material-ui/core';

const link = '/quotes';

const browseConfig = {
    dataId: 'quotes',
    apiBasePath: link,
    createApiPath: (path: string, pageNum: number, pageSize: number, filterValues?: FilterValues | undefined) => {
        if (filterValues) {
            var esc = encodeURIComponent;

            const filters = Object.keys(filterValues)
                .map((k) => {
                    return filterValues[k] && esc(k) + '=' + esc(filterValues[k]) + '&';
                })
                .join('');

            return `${path}?pageNum=${pageNum}&pageSize=${pageSize}&${filters}`;
        }
        return `${path}?pageNum=${pageNum}&pageSize=${pageSize}`;
    },
    tableStructure: [
        {
            title: '',
            align: 'left',
            actions: [
                { title: 'Edit Item/Revision', idField: ['quoteID', 'revision', 'itemID'], action: 'edit', path: link },
                { title: 'Create Job', idField: ['quoteID', 'revision', 'itemID'], action: 'new', path: '/jobs' },
            ],
            sticky: true,
        },
        {
            title: 'Quote No',
            fieldName: 'quoteID',
            showFilter: false,
            showSort: false,
            formatData: (item: any) => `${item['quoteID']}${item['revision']}${item['itemID']}`,
        },
        {
            title: 'Quote Date',
            fieldName: 'createdAt',
            showFilter: false,
            showSort: false,
            formatData: (item: any, fieldName: any) => {
                const d = item[fieldName];
                return `${d.slice(8, 10)}/${d.slice(5, 7)}/${d.slice(0, 4)}`;
            },
        },
        {
            title: 'Award Date',
            fieldName: 'awardedAt',
            showFilter: false,
            showSort: false,
            formatData: (item: any, fieldName: any) => {
                const d = item[fieldName];
                return `${d.slice(8, 10)}/${d.slice(5, 7)}/${d.slice(0, 4)}`;
            },
        },
        {
            title: 'Client',
            fieldName: 'clientName',
            showFilter: false,
            showSort: false,
            formatData: (item: any) => item.clientDetails?.name,
        },
        {
            title: 'Contact',
            fieldName: 'contactName',
            showFilter: false,
            showSort: false,
            formatData: (item: any) => item.clientDetails?.contactDetails?.name,
        },
        {
            title: 'Sales Rep',
            fieldName: 'salesRepName',
            showFilter: false,
            showSort: false,
        },
        {
            title: 'Product Category',
            fieldName: 'productCategoryName',
            showFilter: false,
            showSort: false,
        },
        {
            title: 'Project',
            fieldName: 'project',
            showFilter: false,
            showSort: false,
        },
        {
            title: 'Product Area',
            fieldName: 'productAreaName',
            showFilter: false,
            showSort: false,
        },
        {
            title: 'Enquiry Source',
            fieldName: 'enquirySourceName',
            showFilter: false,
            showSort: false,
        },
        {
            title: 'Quote Status',
            fieldName: 'quoteStatus',
            showFilter: false,
            showSort: false,
        },
        {
            title: 'Combined Price',
            fieldName: 'combinedPrice',
            showFilter: false,
            showSort: false,
            formatData: (item: any, fieldName: any) => (
                <NumberFormat
                    value={item[fieldName]}
                    displayType={'text'}
                    thousandSeparator
                    isNumericString
                    prefix={'$'}
                    decimalScale={2}
                    fixedDecimalScale
                />
            ),
        },
        {
            title: 'Comments',
            fieldName: 'comments',
            showFilter: false,
            showSort: false,
        },
        {
            title: 'Fan Serial',
            fieldName: 'fanSerial',
            showFilter: false,
            showSort: false,
        },
        {
            title: 'Fan Design',
            fieldName: 'fanDesign',
            showFilter: false,
            showSort: false,
            formatData: (item: any) => item.attributes?.fanDesign,
        },
        {
            title: 'Fan Size',
            fieldName: 'fanSize',
            showFilter: false,
            showSort: false,
            formatData: (item: any) => item.attributes?.fanSize,
        },
        {
            title: 'Aura Job File No',
            fieldName: 'auraJobFileNo',
            showFilter: false,
            showSort: false,
            formatData: (item: any) => item.attributes?.auraJobFileNo,
        },
        {
            title: 'NYB Sales Memo No',
            fieldName: 'nybSalesMemoNo',
            showFilter: false,
            showSort: false,
            formatData: (item: any) => item.attributes?.nybSalesMemoNo,
        },
        {
            title: 'Industry',
            fieldName: 'industryName',
            showFilter: false,
            showSort: false,
        },
        {
            title: 'Application',
            fieldName: 'applicationName',
            showFilter: false,
            showSort: false,
        },
    ],
    urlPath: link,
    idField: 'quoteID',
};

const createFilterValues = ({ CreatedFrom, CreatedTo, AwardedFrom, AwardedTo, QuoteNo, FanDesign, FanSize, AuraJobFileNo, NYBSalesMemoNo, ...rest }: any) => {
    const QuoteNoSplit = QuoteNo.match(/[a-z]+|\d+/gi) || [];
    return {
        ...(QuoteNoSplit.length >= 1 && { QuoteID: QuoteNoSplit[0] }),
        ...(QuoteNoSplit.length >= 2 && { Revision: QuoteNoSplit[1] }),
        ...(QuoteNoSplit.length >= 3 && { ItemID: QuoteNoSplit[2] }),
        ...(CreatedFrom !== '' && { 'Created.Min': new Date(CreatedFrom).toISOString() }),
        ...(CreatedTo !== '' && { 'Created.Max': new Date(CreatedTo).toISOString() }),
        ...(AwardedFrom !== '' && { 'Awarded.Min': new Date(AwardedFrom).toISOString() }),
        ...(AwardedTo !== '' && { 'Awarded.Max': new Date(AwardedTo).toISOString() }),
        ...(FanDesign !== '' && { 'Attributes%2eFanDesign': FanDesign }),
        ...(FanSize !== '' && { 'Attributes%2eFanSize': FanSize }),
        ...(AuraJobFileNo !== '' && { 'Attributes%2eAuraJobFileNo': AuraJobFileNo }),
        ...(NYBSalesMemoNo !== '' && { 'Attributes%2eNYBSalesMemoNo': NYBSalesMemoNo }),
        ...rest,
    };
};

const Quotes: React.FC = ({ isLoading, clientList, salesRepList, productAreaList, quoteStatusList, enquirySourceList, industryList, applicationList }: any) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { setFilterValues, clearFilter } = useContext(BrowseContext);

    const [cReset, setCReset] = useState(`c${Date.now()}`);
    const [sReset, setSReset] = useState(`s${Date.now()}`);
    const [paReset, setPaReset] = useState(`pa${Date.now()}`);
    const [qSReset, setQsReset] = useState(`qs${Date.now()}`);
    const [esReset, setEsReset] = useState(`es${Date.now()}`);
    const [iReset, setIReset] = useState(`i${Date.now()}`);
    const [aReset, setAReset] = useState(`a${Date.now()}`);

    return (
        <>
            <Backdrop open={isLoading}>
                <CircularProgress color="primary" />
            </Backdrop>

            <ContentHeader title={'Quotes'} />
            {!isLoading && (
                <Formik
                    initialValues={{
                        QuoteNo: '',
                        ClientID: '',
                        SalesRepID: '',
                        Project: '',
                        ProductAreaID: '',
                        QuoteStatusID: '',
                        EnquirySourceID: '',
                        FanSerial: '',
                        CreatedFrom: '',
                        CreatedTo: '',
                        AwardedFrom: '',
                        AwardedTo: '',
                        industryID: '',
                        applicationID: ''
                    }}
                    onSubmit={async (values) => {
                        setFilterValues(createFilterValues(values));
                    }}
                >
                    {(formProps) => {
                        const { handleSubmit, isSubmitting, setFieldValue, values } = formProps;

                        return (
                            <>
                                <ContentWrapper>
                                    <form onSubmit={handleSubmit}>
                                        <Input label="Quote No" name="QuoteNo" type="text" style={{ width: '10%', marginRight: '1%' }} />

                                        <SelectWithData
                                            reset={qSReset}
                                            setFieldValue={setFieldValue}
                                            label="Quote Status"
                                            name="QuoteStatusID"
                                            options={quoteStatusList}
                                            style={{ width: '10%', marginRight: '1%' }}
                                        />

                                        <SelectWithData
                                            reset={cReset}
                                            setFieldValue={setFieldValue}
                                            label="Client"
                                            name="ClientID"
                                            options={clientList}
                                            style={{ width: '14%', marginRight: '1%' }}
                                        />

                                        <SelectWithData
                                            reset={sReset}
                                            setFieldValue={setFieldValue}
                                            label="Sales Rep"
                                            name="SalesRepID"
                                            options={salesRepList}
                                            style={{ width: '14%', marginRight: '1%' }}
                                        />


                                        <SelectWithData
                                            reset={paReset}
                                            setFieldValue={setFieldValue}
                                            label="Product Area"
                                            name="ProductAreaID"
                                            options={productAreaList}
                                            style={{ width: '14%', marginRight: '1%' }}
                                        />

                                        <SelectWithData
                                            reset={esReset}
                                            setFieldValue={setFieldValue}
                                            label="Enquiry Source"
                                            name="EnquirySourceID"
                                            options={enquirySourceList}
                                            style={{ width: '14%', marginRight: '1%' }}
                                        />

                                        <Input label="Project" name="Project" type="text" style={{ width: '15%',  marginRight: '1%' }} />
                                        <br />
                                        <Input
                                            label="Fan Design"
                                            name="FanDesign"
                                            type="text"
                                            style={{ width: '16%', marginRight: '1%' }}
                                        />

                                        <Input label="Fan Size" name="FanSize" type="text" style={{ width: '14%', marginRight: '1%' }} />
                                        <Input
                                            label="Fan Serial No"
                                            name="FanSerial"
                                            type="text"
                                            style={{ width: '14%', marginRight: '1%' }}
                                        />
                                        <Input
                                            label="Aura Job File No"
                                            name="AuraJobFileNo"
                                            type="text"
                                            style={{ width: '14%', marginRight: '1%' }}
                                        />
                                        <Input
                                            label="NYB Sales Memo No"
                                            name="NYBSalesMemoNo"
                                            type="text"
                                            style={{ width: '14%', marginRight: '1%' }}
                                        />

                                        <SelectWithData
                                            reset={iReset}
                                            setFieldValue={setFieldValue}
                                            label="Industry"
                                            name="industryID"
                                            options={industryList}
                                            style={{ width: '14%', marginRight: '1%' }}
                                        />

                                        <br />

                                        <SelectWithData
                                            reset={aReset}
                                            setFieldValue={setFieldValue}
                                            label="Application"
                                            name="applicationID"
                                            options={applicationList}
                                            style={{ width: '14%', marginRight: '1%' }}
                                        />

                                        <Input
                                            label="Quote Date Start"
                                            name="CreatedFrom"
                                            type="date"
                                            style={{ width: '14%', marginRight: '1%' }}
                                        />

                                        <Input
                                            label="Quote Date End"
                                            name="CreatedTo"
                                            type="date"
                                            style={{ width: '14%', marginRight: '1%' }}
                                        />

                                        <Input
                                            label="Award Date Start"
                                            name="AwardedFrom"
                                            type="date"
                                            style={{ width: '14%', marginRight: '1%' }}
                                        />

                                        <Input label="Award Date End" name="AwardedTo" type="date" style={{ width: '14%' }} />

                                        <Grid container justify="flex-end">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                type="button"
                                                onClick={() => {
                                                    setFieldValue('QuoteNo', '');
                                                    setFieldValue('ClientID', '');
                                                    setCReset(`c${Date.now()}`);
                                                    setFieldValue('SalesRepID', '');
                                                    setSReset(`s${Date.now()}`);
                                                    setFieldValue('Project', '');
                                                    setFieldValue('ProductAreaID', '');
                                                    setPaReset(`pa${Date.now()}`);
                                                    setFieldValue('QuoteStatusID', '');
                                                    setQsReset(`qs${Date.now()}`);
                                                    setFieldValue('EnquirySourceID', '');
                                                    setEsReset(`es${Date.now()}`);
                                                    setFieldValue('FanSerial', '');
                                                    setFieldValue('CreatedFrom', '');
                                                    setFieldValue('CreatedTo', '');
                                                    setFieldValue('AwardedFrom', '');
                                                    setFieldValue('AwardedTo', '');
                                                    setFieldValue('AuraJobFileNo', '');
                                                    setFieldValue('NYBSalesMemoNo', '');
                                                    setIReset(`i${Date.now()}`);
                                                    setAReset(`a${Date.now()}`);
                                                    clearFilter();
                                                }}
                                            >
                                                Reset Filters
                                            </Button>
                                            &nbsp;
                                            <Button variant="contained" color="secondary" type="submit" disabled={isSubmitting}>
                                                Filter Quotes
                                            </Button>
                                        </Grid>
                                    </form>
                                </ContentWrapper>

                                <BrowseData minWidth="1500px" />

                                <Grid container justify="flex-end">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={async () => {
                                            const url = browseConfig.createApiPath(
                                                browseConfig.apiBasePath,
                                                1,
                                                10000000,
                                                createFilterValues(values),
                                            );
                                            const res = await axiosInstance({ url });

                                            if (res.data.length > 0) {
                                                const csvHeaders = [
                                                    'Quote No',
                                                    'Quote Date',
                                                    'Award Date',
                                                    'Client Name',
                                                    'Client State',
                                                    'Sales Rep',
                                                    'Product Area',
                                                    'Combined Price Sell',
                                                    'Combined Price Cost',
                                                ];

                                                const csvData = res.data
                                                    .map(
                                                        ({
                                                            quoteID,
                                                            revision,
                                                            itemID,
                                                            createdAt,
                                                            awardedAt,
                                                            clientDetails,
                                                            salesRepName,
                                                            productAreaName,
                                                            combinedPrice,
                                                            combinedCost,
                                                        }: any) => {
                                                            const { name, state } =
                                                                clientDetails != null
                                                                    ? {
                                                                          name: clientDetails.name,
                                                                          state: clientDetails.addressDetails.state,
                                                                      }
                                                                    : { name: 'NA', state: 'NA' };
                                                            return `${quoteID}${revision}${itemID}${revision},\
                                                    ${createdAt.slice(8, 10)}/${createdAt.slice(5, 7)}/${createdAt.slice(0, 4)},\
                                                    ${awardedAt.slice(8, 10)}/${awardedAt.slice(5, 7)}/${awardedAt.slice(
                                                                0,
                                                                4,
                                                            )},${name},${state},${
                                                                salesRepName != null ? salesRepName : ''
                                                            },${productAreaName},${combinedPrice},${combinedCost}`;
                                                        },
                                                    )
                                                    .join('\n');

                                                const blob = new Blob([`${csvHeaders.join(',')} \n` + csvData], {
                                                    type: 'text/plain;charset=utf-8',
                                                });
                                                saveAs(blob, `report.csv`);
                                            } else {
                                                dispatch(addFeedback('No results found matching filter to export', 'error'));
                                            }
                                        }}
                                    >
                                        Export to .csv
                                    </Button>
                                </Grid>
                            </>
                        );
                    }}
                </Formik>
            )}
        </>
    );
};

const WrappedApp = (props: any) => (
    <BrowseProvider config={browseConfig} pageSize={15}>
        <Quotes {...props} />
    </BrowseProvider>
);

export default WrappedApp;
