import React, { useEffect, useState } from 'react';
import { Container } from '@material-ui/core';
import { axiosInstance } from 'App';

const NewQuotesWrapper: React.FC = ({children}:any) => {
    const [clientList, setClientList] = useState();
    const [salesRepList, setSalesRepList] = useState();
    const [productAreaList, setProductAreaList] = useState();
    const [quoteStatusList, setQuoteStatusList] = useState();
    const [productCategoryList, setProductCategoryList] = useState();
    const [enquirySourceList, setEnquirySourceList] = useState();
    const [industryList, setIndustryList] = useState();
    const [applicationList, setApplicationList] = useState();

    useEffect(() => {
        const fetchClientList = async () => {
            const result = await axiosInstance({ url: '/clients?pageSize=100000' });
            
            const options = result.data.map(({ id, name }: any) => ({
                label: name,
                id
            }));

            setClientList(options);
        };

        fetchClientList();

        const fetchSalesList= async () => {
            const result = await axiosInstance({ url: '/users?role=Sales&enabled=true' });
    
            const options = result.data.map(({ userID, givenName, familyName, enabled }: any) => ({
                label: givenName + ' ' + familyName,
                id: userID,
                group: enabled ? "Active Users" : "Inactive Users"
            })).sort((a:any, b:any) => a.label.localeCompare(b.label)).sort((a:any, b:any) => a.group.localeCompare(b.group))

            setSalesRepList(options);
        };

        fetchSalesList();

        const fetchProductAreaList= async () => {
            const result = await axiosInstance({ url: '/refdata/ProductArea' });

            const options = result.data.map(({ id, data }: any) => ({
                label: data,
                id: id
            }));

            setProductAreaList(options);
        };

        fetchProductAreaList();

        const fetchQuoteStatusList= async () => {
            const result = await axiosInstance({ url: '/refdata/QuoteStatus' });

            const options = result.data.map(({ id, data }: any) => ({
                label: data,
                id: id
            }));

            setQuoteStatusList(options);
        };

        fetchQuoteStatusList();

        const fetchProductCategoryList= async () => {
            const result = await axiosInstance({ url: '/refdata/ProductCategory' });

            const options = result.data.map(({ id, data }: any) => ({
                label: data,
                id: id
            }));

            setProductCategoryList(options);
        };

        fetchProductCategoryList();

        const fetchEnquirySourceList= async () => {
            const result = await axiosInstance({ url: '/refdata/EnquirySource' });

            const options = result.data.map(({ id, data }: any) => ({
                label: data,
                id: id
            }));

            setEnquirySourceList(options);
        };

        fetchEnquirySourceList();

        const fetchIndustryList= async () => {
            const result = await axiosInstance({ url: '/refdata/Industry' });

            const options = result.data.map(({ id, data }: any) => ({
                label: data,
                id: id
            }));

            setIndustryList(options);
        };

        fetchIndustryList();

        const fetchApplicationList= async () => {
            const result = await axiosInstance({ url: '/refdata/Application' });

            const options = result.data.map(({ id, data }: any) => ({
                label: data,
                id: id
            }));

            setApplicationList(options);
        };

        fetchApplicationList();
    }, []);

    

    const isLoading = !(clientList && salesRepList && productAreaList && quoteStatusList && productCategoryList && enquirySourceList && industryList && applicationList);

    return (
        <Container maxWidth={false}>
            {React.Children.map(children, (child: any) => {
                return React.cloneElement(child, { isLoading, clientList, salesRepList, productAreaList, quoteStatusList, productCategoryList, enquirySourceList, industryList, applicationList })
            })}
        </Container>
    );
};

export default NewQuotesWrapper;
