import { useHistory, useParams } from 'react-router-dom';
import { Button, Grid } from '@material-ui/core';
import ContentHeader from 'components/ContentHeader';
import ContentWrapper from 'components/ContentWrapper';
import { useForm } from "react-hook-form";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import makePost from 'helpers/makePost';
import { useDispatch } from 'store';
import { addFeedback } from 'actions';
import SelectWithDataReactHook from '../../../components/SelectWithDataReactHook';
import { useAuth } from 'providers/auth';
import { QuoteFields, quoteFormValidation } from '../components/QuoteFields';

export const formValidation = Yup.object().shape({
    ...quoteFormValidation.fields,
    productCategoryID: Yup.number().typeError('Initial Item must be selected').required('Initial Item required'),
});

const createdAt = () => {
    const tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
    const localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);
    return localISOTime.slice(0, 10)
}
const QuotesNew = ({ isLoading, clientList, salesRepList, productAreaList, quoteStatusList, productCategoryList, enquirySourceList, industryList, applicationList}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { userAttr } = useAuth();
    const { clientId } = useParams();
    
    const { handleSubmit, watch, control, setError, setValue } = useForm({
        resolver: yupResolver(formValidation),
        defaultValues: {
            clientID: clientId,
            contactID: '',
            addressID: '', 
            createdAt: createdAt(),
            awardedAt: '',
            salesRepID: userAttr.userID,
            enquirySourceID: '',
            productAreaID: '',
            quoteStatusID: '1',
            project: '',
            fanSerial: '',
            comments: '',
            productCategoryID: '',
            industryID: '',
            applicationID: ''
        } 
    });
      
    const onSubmit = async (data) => {
        try {
            const response = await makePost({
                url: `/quotes/`,
                data: { 
                    ...data
                },
            });
            
            const { quoteID, itemID, revision } = response.data || {};

            if (quoteID && itemID && revision) {
                dispatch(addFeedback('Quote was successfully initialised', 'success'));
                history.push(`/quotes/edit/${quoteID}/${revision}/${itemID}`)
            } else {
                dispatch(addFeedback(response.data.title || response.data, 'error', response.data.errors));
            }
        } catch (error) {
            const errData = error.response.data;

            Object.keys(errData.errors).map((key) => {
                setError(key.slice(2), errData.errors[key][0]);
                return null;
            });

            dispatch(addFeedback(errData.title || errData, 'error', errData.errors));
        }
    };

    return (
        <>
            <ContentHeader title={'New Quote'} />
            <ContentWrapper isBusy={isLoading}>  
                {!isLoading && <form onSubmit={handleSubmit(onSubmit)}>
                    <QuoteFields control={control} clientList={clientList} quoteStatusList={quoteStatusList} salesRepList={salesRepList} productAreaList={productAreaList} enquirySourceList={enquirySourceList} industryList={industryList} applicationList={applicationList} watch={watch} setValue={setValue} />
                    <SelectWithDataReactHook name='productCategoryID' control={control} label='Initial Item' options={productCategoryList} style={{width: '19%', marginRight: '1%'}} />
                    
                    <Grid container justify="flex-end">
                        <Button
                            variant='contained'
                            color='secondary'
                            type='submit'
                        >
                            Create Quote
                        </Button>
                    </Grid>
                </form>}
            </ContentWrapper>
        </>
    );
};

export default QuotesNew;
