import * as Yup from 'yup';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { FilterValues } from 'types/browse';

const link = '/users';

export const USERS = {
    title: 'Users',
    link,
    actions: [
        {
            key: 'users',
            excludeActions: [''],
            title: 'Browse Users',
            to: link,
            icon: ArrowBackIcon,
        },
        {
            key: 'addUser',
            excludeActions: ['add', 'edit'],
            title: 'Add User',
            to: `${link}/add`,
            icon: AddIcon,
        },
    ],
};

export const apiBasePath = link;

export const tableStructure = [
    {
        title: '',
        align: 'left',
        actions: [
            { title: 'Edit User', idField: 'userID', action: 'edit', path: link },
        ],
        width: '5',
    },
    {
        title: 'Username',
        fieldName: 'username',
        showFilter: false,
        showSort: false,
        textLink: { idField: 'userID', action: 'edit' },
    },
    {
        title: 'Email',
        fieldName: 'email',
        showFilter: false,
        showSort: false,
        textLink: { idField: 'userID', action: 'edit' },
        width: '100',
    },
    {
        title: 'Given Name',
        fieldName: 'givenName',
        showFilter: false,
        showSort: false,
        textLink: { idField: 'userID', action: 'edit' },
        width: '100',
    },
    {
        title: 'Family Name',
        fieldName: 'familyName',
        showFilter: false,
        showSort: false,
        textLink: { idField: 'userID', action: 'edit' },
        width: '100',
    },
    {
        title: 'Phone Number',
        fieldName: 'phoneNumber',
        showFilter: false,
        showSort: false,
        textLink: { idField: 'userID', action: 'edit' },
        width: '200',
    },
    {
        title: 'Roles',
        fieldName: 'roles',
        showFilter: false,
        showSort: false,
        formatData: (item:any, fieldName:any) => item[fieldName].toString(),
        textLink: { idField: 'userID', action: 'edit' },
    },
    {
        title: 'Enabled',
        fieldName: 'enabled',
        showFilter: false,
        showSort: false,
        formatData: (item:any, fieldName:any) => item[fieldName] ? 'True' : 'False',
        textLink: { idField: 'userID', action: 'edit' },
    },
];

export const browseConfig = {
    dataId: 'users',
    apiBasePath,
    createApiPath: (path: string, pageNum: number, pageSize: number, filterValues?: FilterValues | undefined) => {
        if (filterValues?.hasOwnProperty('name') && filterValues['name'].length) {
            return `${path}?pageNum=${pageNum}&pageSize=${pageSize}&searchTerm=${encodeURIComponent(filterValues?.['name'].toString())}`;
        }
        return `${path}?pageNum=${pageNum}&pageSize=${pageSize}`;
    },
    transform: (res: any) => {
        return res.data
    },
    tableStructure,
    urlPath: link,
    idField: 'userID'
};

export const userTransform = (data: any) => {
    return {
        username: data.username,
        email: data.email,
        givenName: data.givenName,
        familyName: data.familyName,
        phoneNumber: data.phoneNumber,
        enabled: data.enabled,
        roles: data.roles,
    };
};

export const addEditConfig = {
    dataId: 'user',
};

export const formStructureAdd = [
    {
        rowTitle: 'Users',
        key: 'usersForm',
        elements: [
            { label: 'Username', key: 'username', fieldName: 'username', inputType: 'string', size: 'sm' },
            { label: 'Email', fieldName: 'email', key: 'email', inputType: 'email', size: 'sm' },
            { label: 'Given Name', key: 'givenName', fieldName: 'givenName', inputType: 'string', size: 'sm' },
            { label: 'Family Name', fieldName: 'familyName', key: 'familyName', inputType: 'string', size: 'sm' },
            { label: 'Phone Number', fieldName: 'phoneNumber', key: 'phoneNumber', inputType: 'string', size: 'sm' },
            { label: 'Roles', fieldName: 'roles', key: 'roles', inputType: 'multipleSelect', defaultValue:[], size: 'sm',
                options: [
                    { value: 'Admin', title: 'Admin' },
                    { value: 'Sales', title: 'Sales' },
                    { value: 'Engineering', title: 'Engineering' },
                    { value: 'Production', title: 'Production' },
                    { value: 'Purchasing', title: 'Purchasing' },
                    { value: 'Finance', title: 'Finance' },
                    { value: 'Marketing', title: 'Marketing' },
                    { value: 'ProjectManagement', title: 'Project Management' },
                    { value: 'Dispatch', title: 'Dispatch' },
                ],
            },
            { label: 'Enabled', key: 'enabled', fieldName: 'enabled', inputType: 'checkbox', size: 'sm' },
            {
                actionType: 'add',
                inputType: 'submit',
                fieldName: 'add',
                key: 'usersAddKey',
                title: 'Add User',
            },
            {
                actionType: 'edit',
                inputType: 'submit',
                fieldName: 'update',
                key: 'updateUserKey',
                title: 'Update User',
            },
        ],
    },
];

export const formStructureEdit = [
    {
        rowTitle: 'Users',
        key: 'usersForm',
        elements: [
            { label: 'Username', key: 'username', fieldName: 'username', inputType: 'string', size: 'sm', disabled: true },
            { label: 'Email', fieldName: 'email', key: 'email', inputType: 'email', size: 'sm' },
            { label: 'Given Name', key: 'givenName', fieldName: 'givenName', inputType: 'string', size: 'sm' },
            { label: 'Family Name', fieldName: 'familyName', key: 'familyName', inputType: 'string', size: 'sm' },
            { label: 'Phone Number', fieldName: 'phoneNumber', key: 'phoneNumber', inputType: 'string', size: 'sm' },
            { label: 'Roles', fieldName: 'roles', key: 'roles', inputType: 'multipleSelect', size: 'sm',
                options: [
                    { value: 'Admin', title: 'Admin' },
                    { value: 'Sales', title: 'Sales' },
                    { value: 'Engineering', title: 'Engineering' },
                    { value: 'Production', title: 'Production' },
                    { value: 'Purchasing', title: 'Purchasing' },
                    { value: 'Finance', title: 'Finance' },
                    { value: 'Marketing', title: 'Marketing' },
                    { value: 'ProjectManagement', title: 'Project Management' },
                    { value: 'Dispatch', title: 'Dispatch' },
                ],
            },
            { label: 'Enabled', key: 'enabled', fieldName: 'enabled', inputType: 'checkbox', size: 'sm' },
            {
                actionType: 'add',
                inputType: 'submit',
                fieldName: 'add',
                key: 'usersAddKey',
                title: 'Add User',
            },
            {
                actionType: 'edit',
                inputType: 'submit',
                fieldName: 'update',
                key: 'updateUserKey',
                title: 'Update User',
            },
        ],
    },
];

export const formValidation = Yup.object().shape({
    username: Yup.string().required('Username required'),
    email: Yup.string().required('Email required'),
    givenName: Yup.string().required('Given Name required'),
    familyName: Yup.string().required('Family Name required'),
    phoneNumber: Yup.string().required('Phone Number required'),
    roles: Yup.array().required('Role required'),
});

export const submitAddTransform = (formData: any) => {
    return {
        username: formData.username,
        email: formData.email,
        givenName: formData.givenName,
        familyName: formData.familyName,
        phoneNumber: formData.phoneNumber,
        enabled: !!formData.enabled,
        roles: formData.roles,
    };
};

export const submitUpdateTransform = (formData: any) => {
    return {
        email: formData.email,
        givenName: formData.givenName,
        familyName: formData.familyName,
        phoneNumber: formData.phoneNumber,
        enabled: formData.enabled,
        roles: formData.roles,
    };
};
